import router from '../router'
import { getCookie } from './cookie';

router.beforeEach((to, from, next) => {
    next()
    if (to.meta.isAuth == false) {
        next()
    } else {
        let cookie = getCookie('qcinfoUserName')
        if (cookie.length) {
            next()
        } else {
            //router.push('/UserLogin')
        }
        //判断是否需要鉴权
    }
})