<template>
  <div id="app">
    <!-- 登录信息 -->
    <div class="login-info">
      <el-dropdown
        v-if="!this.$route.path.includes('/UserLogin') && !this.$route.path.includes('Label')
                                                                                                                    /*&& !this.$route.path.includes('/QCListNew') && !this.$route.path.includes('/QCCategory')*/">
        <span class="el-dropdown-link">
          {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-if="!isLoginIn"><el-button type="primary"
              @click="$router.push('/UserLogin');">登录</el-button></el-dropdown-item>
          <el-dropdown-item v-if="isLoginIn"><el-button type="danger" @click="logOut();">注销</el-button></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- <h1>产品信息</h1> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

import { getCookie, setCookie } from './js/cookie';

export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  provide() {
    return {
      updateUserInfo: this.updateUserInfo //提供updateUserInfo方法给UserLogin.vue
    }
  },
  data() {
    return {
      userName: "未登录",
      isLoginIn: false
    }
  },
  created() {
    this.getCookieUserInfo();
  },
  methods: {
    getCookieUserInfo() {
      let cookie = getCookie('qcinfoUserName');
      if (cookie.length) {
        this.userName = cookie
        this.isLoginIn = true
      }
    },
    logOut() {
      setCookie("", "", -1);
      //location.reload();
      this.userName = "未登录"
      this.isLoginIn = false
    },
    updateUserInfo(userName, isLoginIn) {
      this.userName = userName
      this.isLoginIn = isLoginIn
    }
  }
}
</script>

<style>
/* h1 {
  text-align: center;
} */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  margin: 0px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.login-info {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
</style>
