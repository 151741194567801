import Vue from 'vue'
import Router from 'vue-router'

let QCCategory = () => import('../components/QCCategory.vue')
let QCListNew = () => import('../components/QCListNew.vue')
let QCList = () => import('../components/QCList.vue')
let QCDetailInfo = () => import('../components/QCDetailInfo.vue')
let UserLogin = () => import('../components/UserLogin.vue')
let QCLabel = () => import('../components/QCLabel.vue')
let QCLabelNew = () => import('../components/QCLabelNew.vue')
let QCBatchLabel = () => import('../components/QCBatchLabel.vue')
let QCShipLabel = () => import('../components/QCShipLabel.vue')
//let MPS = () => import('../components/MPS.vue')
//let APS = () => import('../components/APS.vue')
//let HelloWorld = () => import('../components/HelloWorld.vue') //默认页面

Vue.use(Router)
export default new Router({
    routes: [
        { //设置第一个路由页面，含参数id
            path: '/ProductCategory',
            name: 'ProductCategory',
            component: QCCategory
        },
        { //设置第一个路由页面，含参数id
            path: '/ProductList/:category_en',
            name: 'ProductList',
            component: QCListNew
        },
        { //设置第一个路由页面，含参数id
            path: '/QCList',
            name: 'QCList',
            component: QCList, meta: {
                keepAlive: true
            }
        },
        { //设置第二个路由页面，不含参数
            path: '/QCDetailInfo/:iden',
            name: 'QCDetailInfo',
            component: QCDetailInfo
        },
        {
            path: '/QCLabel/:iden/:isEng/:showYarnCount',
            name: 'QCLabel',
            component: QCLabel
        },
        {
            path: '/QCLabelNew/:iden/:isEng/:showYarnCount',
            name: 'QCLabelNew',
            component: QCLabelNew
        },
        {
            path: '/QCBatchLabel/:salesOrderDetailId/:dyeOrderDetailId/:dyeProductionScheduleId/:orderType/:piIdenList?',
            name: 'QCBatchLabel',
            component: QCBatchLabel
        },
        {
            path: '/QCShipLabel/:salesOrderDetailId/:dyeOrderDetailId/:dyeProductionScheduleId/:orderType/:piIdenList?',
            name: 'QCShipLabel',
            component: QCShipLabel
        },
        /* {
            path: '/MPS',
            name: 'MPS',
            component: MPS
        },
        {
            path: '/APS',
            name: 'APS',
            component: APS
        }, */
        { //设置第三个路由页面，不含参数
            path: '/UserLogin',
            name: 'UserLogin',
            component: UserLogin, meta: {
                isAuth: false
            }
        },
        { //设置根目录对应页面
            path: '/',
            redirect: '/ProductCategory',
            component: QCCategory
        }
    ]
})