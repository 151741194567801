export const setCookie = (c_name, c_pwd, exdays) => {
    var exdate = new Date(); //获取时间
    exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
    //字符串拼接cookie
    if (exdays) {
        window.document.cookie =
            "qcinfoUserName" + "=" + encodeURIComponent(c_name) + ";path=/;expires=" + exdate.toGMTString();
        window.document.cookie =
            "qcinfoToken" + "=" + encodeURIComponent(c_pwd) + ";path=/;expires=" + exdate.toGMTString();
    } else {
        window.document.cookie = "qcinfoUserName" + "=" + encodeURIComponent(c_name) + ";path=/;expires=";
        window.document.cookie = "qcinfoToken" + "=" + encodeURIComponent(c_pwd) + ";path=/;expires=";
    }
}
export const getCookie = (name) => {
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
        var arr = arrcookie[i].split("=");
        if (arr[0] == name) {
            return decodeURIComponent(arr[1]);
        }
    }
    return "";
}